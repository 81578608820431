import React from 'react'

import { Avatar, Box, Typography } from '@mui/material'

const DriverInfo = ({ driver, image_url, plate, vehicle }) => (
  <Box sx={{ display: 'flex', flex: 1, gap: 2, alignItems: 'center' }}>
    <Avatar src={image_url} sx={{ height: 80, width: 80 }} />
    <Box>
      <Typography>{driver}</Typography>
      <Typography>{vehicle}</Typography>
      <Typography>{plate}</Typography>
    </Box>
  </Box>
)

export default React.memo(DriverInfo)