import React from 'react'

import { Typography, Grid2 as Grid } from '@mui/material'

const StepItem = ({ active }) => (
  <Grid size={3} height={8} borderRadius={4} bgcolor={active ? 'primary.main' : 'grey'} />
)

const StatusBar = ({ transportStep, label }) => (
  <Grid container>
    <Grid container size={12} spacing={2}>
      <StepItem active={transportStep > 0} />
      <StepItem active={transportStep > 1} />
      <StepItem active={transportStep > 2} />
      <StepItem active={transportStep > 3} />
    </Grid>
    <Grid size={12}>
      <Typography fontSize={14}>{label}</Typography>
    </Grid>
  </Grid>
)

export default React.memo(StatusBar)
