import React from 'react'
import { hooks } from '@front/volcanion'
import { HookUtils } from '@front/volcanion/utils'

import { Loader } from '@front/squirtle'
import { useExtractOrderFromUser } from '@static/hooks/auth'

const getStepStatus = (transport_status, order_status) => {
  switch (transport_status) {
    case 'started':
      return 1
    case 'at_src':
      return 2
    case 'on_board':
    case 'in_route':
    case 'at_dst':
    case 'off_board':
      return 3
    case 'complete':
      return 4
    default:
      return order_status === 'complete' ? 4 : 0
  }
}

const withContainer = Component => props => {
  const [order, orderState] = useExtractOrderFromUser({
    populate: ['active_transport']
  })

  const transportStatus = hooks.useTransportStatus(order?.active_transport, { translated: true, load_type: 'user', emptyValue: null })
  const orderStatus = hooks.useOrderStatus(order, { translated: true, emptyValue: null })
  const transportStep = getStepStatus(order?.active_transport?.status, order?.status)
  const label = transportStatus ?? orderStatus

  const mergedProps = {
    transportStep,
    label
  }

  return (
    <Loader isLoading={HookUtils.getLoadingState([orderState])}>
      <Component {...mergedProps} />
    </Loader>
  )
}

export default withContainer