import React, { useCallback } from 'react'

import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const CLIENT_MAIN_SITE = hooks.useRelayConstant(['CLIENT_MAIN_SITE'])
  const onClick = useCallback(() => !!CLIENT_MAIN_SITE && window.open(`${CLIENT_MAIN_SITE}/c/suivi-de-course-webview`), [CLIENT_MAIN_SITE])

  const mergedProps = {
    onClick
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
