import React from 'react'

import { I18n } from '@front/volcanion'

import { Box, Grid2 as Grid, Typography, Button, Icon } from '@mui/material'

const Extra = ({ hidden, CLIENT_BOOKER_APP, CLIENT_BOOKER_SITE, isMobile, isApple, isValidToken }) =>
  <Box hidden={!!hidden} sx={{ flex: 1 }}>
    <Grid container justifyContent='center' alignItems='center' sx={{ height: '100%' }} rowSpacing={3}>
      <Grid size={12}>
        <Box hidden={!isValidToken} sx={{ backgroundColor: 'success.main', borderRadius: 4, p: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box sx={{ fontSize: 40, mr: 2 }} color='extraWhite'>
              <Icon fontSize='inherit'>{'check_circle'}</Icon>
            </Box>
            <Box>
              <Typography color='extraWhite' fontWeight={'bold'} textAlign={'center'} fontSize={!isMobile ? 'h6.fontSize' : 40} alignItems='center'>
                {I18n.t('password.success.title')}
              </Typography>
            </Box>
          </Box>
          <Typography color='extraWhite' fontWeight={'bold'} textAlign={'center'} fontSize={!isMobile ? 'h6.fontSize' : 30}>{I18n.t('password.success.description')}</Typography>
        </Box>
        {!isValidToken && <Typography color='error.main' textAlign={'center'} fontSize={!isMobile ? 'h6.fontSize' : 33} fontWeight={'bold'}>{I18n.t('password.token.expired')}</Typography>}
      </Grid>
      <Grid size={10} hidden={!isMobile}>
        <Button
          fullWidth
          variant={'contained'}
          href={CLIENT_BOOKER_APP}
          sx={{ p: 3, fontSize: 33 }}
          endIcon={<Icon fontSize={'large'}>{!isApple ? 'android' : 'apple'}</Icon>}
        >
          {I18n.t('password.redirect.app')}
        </Button>
      </Grid>
      <Grid size={10} hidden={!!isMobile}>

        <Button
          fullWidth
          variant={'contained'}
          href={CLIENT_BOOKER_SITE}
          size='large'
        >
          {I18n.t('password.redirect.web')}
        </Button>
      </Grid>
    </Grid>
  </Box >


export default Extra
