import React, { useCallback, useEffect, useState } from 'react'

import { hooks } from '@front/volcanion'

import Callbacks from './callbacks'

const withContainer = Component => props => {
  const isAuthenticated = hooks.useAuthenticated()
  const { share_id } = hooks.useParams()
  const [, extractTokenFromShareId] = hooks.useAuthFunction('extractTokenFromShareId')
  const [isLoading, setIsLoading] = useState(false)

  const handleExtractTokenFromShareId = useCallback(Callbacks.handleExtractTokenFromShareIdHandler(extractTokenFromShareId, setIsLoading), [extractTokenFromShareId, setIsLoading])

  useEffect(() => {
    if (share_id)
      handleExtractTokenFromShareId(share_id)
  }, [share_id])

  const mergedProps = {
    isLoading,
    isAuthenticated,
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer