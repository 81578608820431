import React from 'react'
import { CDNImage, I18n } from '@front/volcanion'

import { Box, Link } from '@mui/material'

const HeaderSection = ({ onClick }) => (
  <Box bgcolor={'primary.main'} display={'flex'} justifyContent='space-between' alignItems='center' p={1} onClick={onClick} >
    <CDNImage name='download_app' category='images' style={{ height: 36, width: 36 }} />
    <Link component="button" fontSize={12} color='white'>
      {I18n.t('order.dowloadLink')}
    </Link>
  </Box>
)

export default React.memo(HeaderSection)
