import React from 'react'
import { hooks } from '@front/volcanion'
import { HookUtils } from '@front/volcanion/utils'

import { Loader } from '@front/squirtle'
import { useExtractOrderFromUser } from '@static/hooks/auth'

import moment from 'moment'

const withContainer = Component => props => {
  const { CALL_DELAY_AFTER_ORDER } = hooks.useRelayConstants(['CALL_DELAY_AFTER_ORDER'])
  const [order, orderState] = useExtractOrderFromUser({ populate: ['assigned_transport.info', 'do.customerinfo', 'service'] })

  const finalizedAt = order?.assigned_transport?.info?.finalizedAt
  const call_delay_expired = !!finalizedAt ? moment(finalizedAt).add(CALL_DELAY_AFTER_ORDER, 'minutes').isAfter(moment()) : false
  const hide_driverinfo = !!order?.service ? order?.service?.hide_driverinfo : order?.do?.customerinfo?.hide_driverinfo

  const showDriverInfo = !!order?.assigned_transport && !hide_driverinfo
  const showCallButton = !!order?.assigned_transport && !call_delay_expired

  const mergedProps = {
    showDriverInfo,
    showCallButton,
    order
  }

  return (
    <Loader isLoading={HookUtils.getLoadingState([orderState])}>
      <Component {...mergedProps} />
    </Loader>
  )
}

export default withContainer