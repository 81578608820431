
import React from 'react'

import { Loader } from '@front/squirtle'

import {
  MapSection,
  HeaderSection,
  InformationSection,
  NotFoundSection
} from './Sections'

const OrderSection = () => (
  <>
    <MapSection />
    <InformationSection />
  </>
)

const OrderView = ({ isLoading, isAuthenticated }) => {
  return (
    <>
      <HeaderSection />
      <Loader isLoading={isLoading}>
        {!!isAuthenticated && <OrderSection />}
        {!isAuthenticated && <NotFoundSection />}
      </Loader>
    </>
  )
}

export default React.memo(OrderView)
