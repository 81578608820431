import React from 'react'
import { CDNImage, I18n } from "@front/volcanion"

import { Box, Typography } from "@mui/material"

const NotFoundSection = () => (
  <Box height={1} rowGap={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
    <CDNImage name='logo' category='images' style={{ height: 'auto', width: 250 }} />
    <Typography align='center'>{I18n.t('order.notFound')}</Typography>
  </Box>
)

export default React.memo(NotFoundSection)