import React from 'react'
import { hooks } from '@front/volcanion'
import { HookUtils } from '@front/volcanion/utils'

import { Loader } from '@front/squirtle'
import { useExtractOrderFromUser } from '@static/hooks/auth'

const withContainer = Component => props => {
  const [order, orderState] = useExtractOrderFromUser({
    populate: ['assigned_transport.info', 'assigned_transport.driver.info', 'assigned_transport.vehicle.info.model.brand']
  })

  const transport = order?.assigned_transport
  const [{ photoURL }] = hooks.useHandlePhoto(transport?.driver?.user_id)
  const default_image_name = _.upperCase(transport?.driver?.info?.gender) === 'F' ? 'female_driver' : 'driver'
  const default_image_cdn = hooks.useCDNAsset(default_image_name, 'images', { extension: 'png' })

  const image_url = photoURL || default_image_cdn
  const driver = transport?.driver?.info?.first_name
  const plate = transport?.vehicle?.info?.plaque
  const vehicle = _.compact([
    _.startCase(transport?.vehicle?.info?.model?.brand?.name),
    _.startCase(transport?.vehicle?.info?.model?.name)])
    .join(' - ')

  const mergedProps = {
    driver,
    image_url,
    plate,
    vehicle
  }

  return (
    <Loader isLoading={HookUtils.getLoadingState([orderState])}>
      <Component {...mergedProps} />
    </Loader>
  )
}

export default withContainer