import React from 'react'
import { I18n } from '@front/volcanion'

import { Call } from '@front/squirtle/elements'
import { Box, Icon, Typography } from '@mui/material'

import DriverInfoSection from './DriverInfo'
import StatusBarSection from './StatusBar'

const InformationSection = ({ showDriverInfo, showCallButton, order }) => (
  <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', p: 2, gap: 3 }}>
    <Typography fontSize={18} fontWeight={600} align="center">
      {I18n.t('order.order_client_id', { order_client_id: order?.order_client_id })}
    </Typography>
    <StatusBarSection />
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      {!!showDriverInfo && <DriverInfoSection />}
      {!!showCallButton && (
        <Call.Button
          order_id={order?.order_id}
          origin={'customer'}
          target={'driver'}
          label={I18n.t('action.call')}
          _button={{
            startIcon: <Icon>{'phone'}</Icon>,
            sx: { textTransform: 'none' },
            fullWidth: !showDriverInfo,
            size: 'large'
          }}
        />
      )}
    </Box>
  </Box>
)

export default React.memo(InformationSection)