import React from 'react'

import { hooks } from '@front/volcanion'
import { Loader, Router, Routes, Route } from '@front/squirtle'

import ResetPassword from './ResetPassword'
import OrderView from './OrderView'

import './styles.scss'

const Pages = props => {
  const isReady = hooks.useReadyStatus()
  return (
    <Loader isLoading={!isReady}>
      <Router>
        <Routes>
          <Route path='/static/reset-password/*' element={<ResetPassword />} />
          <Route path='/static/share/:share_id' element={<OrderView />} />
        </Routes>
      </Router>
    </Loader>
  )
};

export default React.memo(Pages)
