class Callbacks {
  static handleExtractTokenFromShareIdHandler(extractTokenFromShareId, setIsLoading) {
    return async function handleExtractTokenFromShareId(share_id) {
      try {
        setIsLoading(true)
        await extractTokenFromShareId(share_id)
      } catch (err) {
        console.warn('Cannot extract token from share_id')
      } finally {
        setIsLoading(false)
      }
    }
  }
}

export default Callbacks
