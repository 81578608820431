import React, { useCallback, useEffect } from 'react'

import { HookUtils } from '@front/volcanion/utils'
import { hooks } from '@front/volcanion'
import { Loader } from '@front/squirtle'

import { useExtractOrderFromUser } from '@static/hooks/auth'

import moment from 'moment'

import Callbacks from './callbacks'

const withContainer = Component => props => {
  const [liveETAExpiry] = hooks.useSetting('live_eta_expiry')
  const [requestETAFunction] = hooks.useModelFunction('eta', 'request')
  const [order, orderState] = useExtractOrderFromUser({
    populate: ['active_transport', 'assigned_transport', 'source', 'current_target.eta', 'info']
  })

  const order_id = order?.order_id
  const vehicle_id = order?.active_transport?.vehicle
  const source = order?.source
  const live_eta = order?.current_target?.eta?.time
  const includeSource = !!order?.assigned_transport ? _.includes(['started', 'at_src'], order?.assigned_transport?.status) : true
  const includeDestination = _.includes(['on_board', 'in_route', 'at_dst'], order?.assigned_transport?.status)
  const estimatedDelay = !!live_eta
    ? moment(live_eta).diff(moment(), 'minutes')
    : moment(order?.info?.src_datetime).diff(moment(order?.requestedAt), 'minutes')

  const requestETA = useCallback(Callbacks.requestETAHandler(requestETAFunction), [requestETAFunction])

  useEffect(() => {
    if (!!order_id && _.isFinite(liveETAExpiry) && order?.active_transport?.status === 'started') {
      requestETA(order_id)
      const request = setInterval(requestETA, (liveETAExpiry * 1000) / 2, order_id)
      return () => clearInterval(request)
    }
  }, [order_id, liveETAExpiry, order?.active_transport?.status])


  const mergedProps = {
    estimatedDelay,
    includeSource,
    includeDestination,
    order_id,
    source,
    vehicle_id
  }

  return (
    <Loader isLoading={HookUtils.getLoadingState([orderState])}>
      <Component {...mergedProps} />
    </Loader>
  )
}

export default withContainer