import { useMemo } from 'react'
import { hooks } from '@front/volcanion'

const useExtractOrderFromUser = (options) => {
  const user_info = hooks.useActiveUserInfo()
  const [order, orderState] = hooks.useModel('order', [user_info?.order], _.merge({}, options, { single: true }))
  return [order, orderState]
}

export {
  useExtractOrderFromUser
}
