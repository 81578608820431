export default {
  password: {
    label: "Password",
    confirm: "Confirm password",
    token: {
      expired: "Expired password change request."
    },
    success: {
      title: "Congratulation !",
      description: "Your new password has just been updated.",
    },
    error: {
      not_same: "Password not identical"
    },
    new: {
      title: "Reset password",
      description: "Please enter new password"
    },
    rules: {
      length: "8 caractères",
      upper: "une majuscule",
      lower: "une minuscule",
      number: "un chiffre"
    },
    complexity: {
      level: "Password complexity :",
      weak: "Low",
      good: "Medium",
      strong: "High"
    },
    redirect: {
      app: "Continue in the app",
      web: "Continue on the web booker"
    }
  },
  order: {
    order_client_id: 'Order n°{{order_client_id}}',
    cancel: 'Cancel order',
    notFound: 'Order unavailable',
    dowloadLink: 'Haven\'t downloaded the app yet?',
    status: {
      complete: "Order completed",
      canceled: "Order canceled",
      created: "Order registered",
      attributed: "Order assigned",
      standby: "Searching for a driver...",
      planned: "Order planned"
    },
  },
  action: {
    save: 'Save',
    confirm: 'Confirm',
    cancel: 'Cancel',
    call: 'Call',
  },
  transportStatus: {
    created: {
      user: "Pending"
    },
    standby: {
      user: "Pending"
    },
    planned: {
      user: "Order planned..."
    },
    started: {
      user: "Driver approaching..."
    },
    at_src: {
      user: "Driver on site..."
    },
    on_board: {
      user: "Passenger on board..."
    },
    at_dst: {
      user: "Arrived at destination"
    },
    off_board: {
      user: "Processing payment..."
    },
    complete: {
      user: "Completed"
    },
    canceled: {
      user: "Canceled"
    },
    not_loaded: {
      user: "Not loaded"
    },
    late_cancel: {
      user: "Late cancellation"
    }
  },
  error: {
    default: "Error happened"
  },
  success: {
    order_cancel: "Order cancel"
  }
}
