import React from 'react'
import { hooks } from '@front/volcanion'
import { GeoUtils } from '@front/volcanion/utils'

import { MapFactory } from '@front/squirtle'

import { Box, Typography } from '@mui/material'

import './styles.scss'

const baseStyle = {
  iconType: 'cdn',
  circleScale: 1,
  color: 'transparent',
  outlineColor: 'transparent',
  circleOutlineColor: 'transparent'
}
const MapSection = ({ order_id, vehicle_id, source, estimatedDelay, includeSource, includeDestination }) => {
  const theme = hooks.useTheme()
  return (
    <MapFactory.MapContainer mapStyle={{ height: '60%' }} mapId='order-view'>
      <MapFactory.SuperLayer fitToBounds followBounds boundOptions={{ animate: true, padding: [50, 50] }} >
        {includeSource && (
          <MapFactory.Marker
            key={order_id}
            config={{
              model_name: 'order',
              ids: [order_id],
              populate: ['source'],
              coordinates_path: 'source.coordinates'
            }}
            styles={{
              icon: 'source', iconColor: theme?.palette?.primary?.main, iconSize: [24, 24], tooltipAnchor: [0, -24], ...baseStyle
            }}
          >
            <MapFactory.Tooltip permanent direction='top' className='leaflet-tooltip-own'>
              <Box display='flex'>
                <Box px={1} pt={.5} pb={.6} color='white' bgcolor='primary.main' sx={{ borderTopLeftRadius: 4, borderBottomLeftRadius: 4 }}>
                  <Typography fontSize={14}>{_.max([estimatedDelay, 2])}</Typography>
                </Box>
                <Box px={1} pt={.5} pb={.6}>
                  <Typography fontSize={14}>{GeoUtils.getCompactAddress(source)}</Typography>
                </Box>
              </Box>
            </MapFactory.Tooltip>
          </MapFactory.Marker>
        )}
        {includeDestination && (
          <MapFactory.Marker
            key={order_id}
            config={{
              model_name: 'order',
              ids: [order_id],
              populate: ['destination'],
              coordinates_path: 'destination.coordinates',
            }}
            styles={{ icon: 'destination', iconColor: theme?.palette?.secondary?.main, iconSize: [24, 24], ...baseStyle }}
          />
        )}
        <MapFactory.Marker
          key={vehicle_id}
          config={{
            model_name: 'vehicle',
            ids: [vehicle_id],
            populate: ['current_location'],
            coordinates_path: 'current_location.coordinates'
          }}
          styles={{ icon: 'vehicle', iconColor: 'black', iconSize: [40, 40], ...baseStyle }}
        />
      </MapFactory.SuperLayer>
    </MapFactory.MapContainer >
  )
}

export default React.memo(MapSection)