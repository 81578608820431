export default {
  password: {
    label: "Mot de passe",
    confirm: "Confirmation mot de passe",
    token: {
      expired: "Demande de changement de mot de passe expiré."
    },
    success: {
      title: "Félicitations !",
      description: "Votre nouveau mot de passe vient d’être mis à jour.",
    },
    error: {
      not_same: "Mot de passe non identique"
    },
    new: {
      title: "Réinitialisation du mot de passe",
      description: "Veuillez entrer un nouveau mot de passe"
    },
    rules: {
      length: "8 caractères",
      upper: "une majuscule",
      lower: "une minuscule",
      number: "un chiffre"
    },
    complexity: {
      level: "Complexité du mot de passe :",
      weak: "Faible",
      good: "Moyenne",
      strong: "Élevée"
    },
    redirect: {
      app: "Continuer dans l'application",
      web: "Continuer sur le web booker"
    }
  },
  order: {
    order_client_id: 'Commande n°{{order_client_id}}',
    cancel: 'Annuler la course',
    notFound: 'Course indisponible',
    dowloadLink: 'Vous n\'avez pas encore l\'application ?',
    status: {
      complete: 'Course terminée',
      canceled: 'Course annulée',
      created: 'Course enregistrée',
      attributed: 'Course attribuée',
      standby: 'Recherche chauffeur...',
      planned: 'Course planifier',
    },
  },
  action: {
    save: 'Sauvegarder',
    confirm: 'Confirmer',
    cancel: 'Annuler',
    call: 'Appeler',
  },
  transportStatus: {
    created: {
      user: "En attente"
    },
    standby: {
      user: "En attente"
    },
    planned: {
      user: "Course planifié..."
    },
    started: {
      user: "Chauffeur en approche..."
    },
    at_src: {
      user: "Chauffeur sur Place..."
    },
    on_board: {
      user: "Passager à bord..."
    },
    at_dst: {
      user: "Arrivé à destination"
    },
    off_board: {
      user: "En cours de paiement..."
    },
    complete: {
      user: "Terminé"
    },
    canceled: {
      user: "Supprimé"
    },
    not_loaded: {
      user: "Non chargé"
    },
    late_cancel: {
      user: "Annulation tardive"
    }
  },
  error: {
    default: "Une erreur est survenue"
  },
  success: {
    order_cancel: "Course annuler"
  }
}
